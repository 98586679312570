.year {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.month {
  margin: 5px 5px 15px 5px;
}

.month-name {
  color: #00044e;
  font-weight: bold;
}

.day {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
}

.date {
  width: 30px;
  height: 30px;
  background: white;
  border: none;
  outline: none;
}

.date.in-month:hover {
  cursor: pointer;
  background: #aec6d3;
}

.today {
  background: #00044e;
  color: white;
}

.date.prev-month,
.date.next-month {
  color: grey;
}
